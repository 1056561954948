import './landingDaoIntro.css';

import parse, {
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import BottomBackgroundMobile
  from '../../assets/images/app-bottom-background.png';
import BottomBackground from '../../assets/images/web-bottom-background.png';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { commonLayout } from '../../utils/constant';
import Button from '../Button';
import Fold from '../Fold';
import {
  introList,
  word,
} from './config';

const LandingDaoIntro = () => {
  const { isMobileWidth } = useWindowDimensions();

  // for parse special case a tag
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.attribs && domNode.attribs.id === 'opensea-url') {
        return (
          <a href={process.env.REACT_APP_OPENSEA!} target="_blank" rel="noreferrer">
            二級市場
          </a>
        );
      }
    },
  };

  return (
    <Layout>
      <Content>
        <TitleLayout>
          <span id="landing-intro-title">{word.title}</span>
        </TitleLayout>
        <IntroPart>{parse(word.contentHtml)}</IntroPart>
        <FoldLayout>
          {introList.map((item, idx) => (
            <Fold key={`fold-${idx}`} title={item.title} content={parse(item.content, options)} />
          ))}
        </FoldLayout>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Link style={{ textDecoration: 'none' }} to="/dao">
            <Button title="前往 知DAO" isMobileWidth={isMobileWidth} />
          </Link>
        </div>
      </Content>

      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <img
          style={{ maxWidth: `${isMobileWidth ? '100%' : 'auto'}` }}
          src={isMobileWidth ? BottomBackgroundMobile : BottomBackground}
          alt="bottom-background"
        />
      </div>
    </Layout>
  );
};

export default LandingDaoIntro;

const Layout = styled.div`
  width: 100%;
  background: linear-gradient(#6699ff 20%, #66ffcc 70%, #aaff88 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Content = styled.div`
  width: ${commonLayout.viewWidth};
  max-width: 100vw;
  padding: 0px ${commonLayout.viewPadding};
  box-sizing: border-box;
`;

const TitleLayout = styled.div`
  width: 100%;
  display: inline-block;
  line-height: 1;
  vertical-align: bottom;
  margin-top: 92px;
  margin-bottom: 16px;
`;

const IntroPart = styled.div`
  margin-bottom: 36px;
`;

const FoldLayout = styled.div`
  margin-bottom: 70px;
`;
