import DiscordLogo from '../../assets/images/footer/dc-icon.svg';
import FbLogo from '../../assets/images/footer/fb-icon.svg';
import IgLogo from '../../assets/images/footer/ig-icon.svg';
import TtLogo from '../../assets/images/footer/twitter-icon.svg';
import YtLogo from '../../assets/images/footer/yt-icon.svg';
import { externalUrl } from '../../config';

export const footerImages = [
  {
    img: DiscordLogo,
    url: externalUrl.discord,
  },
  {
    img: FbLogo,
    url: externalUrl.facebook,
  },
  {
    img: IgLogo,
    url: externalUrl.instagram,
  },
  {
    img: TtLogo,
    url: externalUrl.twitter,
  },
  {
    img: YtLogo,
    url: externalUrl.youtube,
  },
];
