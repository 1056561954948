import './daoIntro.css';

import parse from 'html-react-parser';
import styled from 'styled-components';

import BottomBackgroundMobile
  from '../../assets/images/app-bottom-background.png';
import BottomBackground from '../../assets/images/web-bottom-background.png';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { commonLayout } from '../../utils/constant';
import { zhidaoOnTallyURL } from '../../utils/tallyHelper';
import Button from '../Button';
import Fold from '../Fold';
import VaultBalance from '../VaultBalance';
import TallyInstructions from './components/Instructions';
import Proposals from './components/proposals';
import {
  daoIntro,
  recentActivity,
  tallyInterface,
} from './config';

const DaoIntro = () => {
  const { isMobileWidth } = useWindowDimensions();

  return (
    <Layout>
      <Content>
        <TitleLayout>
          <span id="dao-intro-title">{daoIntro.title}</span>
        </TitleLayout>
        <IntroPart>{parse(daoIntro.contentHtml1)}</IntroPart>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: isMobileWidth ? 'column' : 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <VaultBalance isMobileWidth={isMobileWidth} />
          <div style={{ width: 70, height: 32 }} />
          <div style={{ flexGrow: 1, width: isMobileWidth ? '100%' : 'auto' }}>
            <a style={{ textDecoration: 'none' }} href={zhidaoOnTallyURL()} target="_blank" rel="noreferrer">
              <Button title="前往 知DAO on Tally " isMobileWidth={true} height={61} />
            </a>
          </div>
        </div>
        <div style={{ height: 32 }} />
        <IntroPart>{parse(daoIntro.contentHtml2)}</IntroPart>

        <TitleLayout>
          <span id="dao-subtitle">{tallyInterface.title}</span>
        </TitleLayout>
        <IntroPart>{parse(tallyInterface.contentHtml1)}</IntroPart>
        <IntroPart>{parse(tallyInterface.contentHtml2)}</IntroPart>

        <Fold title="介面導覽" content={<TallyInstructions />} />

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>

        <TitleLayout>
          <span id="dao-subtitle">{recentActivity.title}</span>
        </TitleLayout>
        {recentActivity.contentHtml ? <IntroPart>{parse(recentActivity.contentHtml)}</IntroPart> : null}

        <Proposals />
      </Content>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <img
          style={{ maxWidth: `${isMobileWidth ? '100%' : 'auto'}` }}
          src={isMobileWidth ? BottomBackgroundMobile : BottomBackground}
          alt="bottom-background"
        />
      </div>
    </Layout>
  );
};

export default DaoIntro;

const Layout = styled.div`
  width: 100%;
  background: linear-gradient(#6699ff 20%, #66ffcc 70%, #aaff88 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Content = styled.div`
  width: ${commonLayout.viewWidth};
  max-width: 100vw;
  padding: 0px ${commonLayout.viewPadding};
  box-sizing: border-box;
`;

const TitleLayout = styled.div`
  width: 100%;
  display: inline-block;
  line-height: 1;
  vertical-align: bottom;
  margin-top: 92px;
  margin-bottom: 16px;
`;

const IntroPart = styled.div`
  margin-bottom: 36px;
`;
