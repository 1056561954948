import { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { commonLayout } from '../../utils/constant';
import { carouselMedia, carouselMediaMobile, carouselTitles } from './config';
import './daoCarousel.css';
import useWindowDimensions from '../../hooks/useWindowDimension';

enum MediaType {
  Video = 'video',
  Image = 'image',
}

const DaoCarousel = () => {
  const { isMobileWidth, width } = useWindowDimensions();

  const carouselData = useMemo(() => {
    return isMobileWidth ? carouselMediaMobile : carouselMedia;
  }, [isMobileWidth]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <Carousel
        width={'100vw'}
        infiniteLoop={true}
        autoPlay={true}
        showThumbs={false}
        showStatus={false}
        interval={3000}
        dynamicHeight={false}
      >
        {carouselData.map((item, idx) => {
          return (
            <div key={`dao-carousel-${idx}`} style={{ overflow: 'hidden' }}>
              {item.type === MediaType.Image ? (
                <img src={item.src} alt="" />
              ) : (
                <video
                  style={{
                    display: 'block',
                    width: isMobileWidth ? '100%' : '100vw',
                    height: isMobileWidth ? `${width * 1.28}px` : `${width * 0.260416}px`,
                    objectFit: 'cover',
                    objectPosition: '50% bottom',
                  }}
                  autoPlay
                  muted
                  loop
                >
                  <source src={item.src} type="video/mp4"></source>
                </video>
              )}
              <div
                style={{
                  position: 'fixed',
                  width: '100%',
                  backgroundColor: 'transparent',
                  height: '100%',
                  bottom: 0,
                  color: 'white',
                  fontSize: 50,
                  boxSizing: 'border-box',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: `${commonLayout.viewWidth}`,
                    maxWidth: '100vw',
                    height: '100%',
                    padding: `${carouselTitles[idx].padding}?${carouselTitles[idx].padding}:0`,
                    color: 'black',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: `${carouselTitles[idx].justify}`,
                    alignItems: `${carouselTitles[idx].align}`,
                    position: 'fixed',
                    bottom: `${carouselTitles[idx].bottom}`,
                    top: `${carouselTitles[idx].top}`,
                  }}
                >
                  <div id={`dao-carousel-subtite-${carouselTitles[idx].id}`}>
                    {carouselTitles[idx].subtitle}
                  </div>
                  <div id={`dao-carousel-title-${carouselTitles[idx].id}`}>{carouselTitles[idx].title}</div>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default DaoCarousel;
