import { useState } from 'react';
import styled from 'styled-components';
import { MenuConfig, MobileMenuConfig } from '../../config';
import MenuItem from '../MenuItem';
import MenuItemMobile from '../MenuItemMobile';
import Modal from 'react-modal';
import LogoDark from '../../../../assets/images/logo-dark.png';
import Close from '../../../../assets/images/close-cross.png';
import useWindowDimensions from '../../../../hooks/useWindowDimension';
import MenuIcon from '../../../../assets/images/header/mobile/menu-icon.png';

const customStyles = {
  overlay: {
    zIndex: 200,
    background: 'rgba(255, 255, 255, 0.9)',
  },
  content: {
    top: '0%',
    left: '0%',
    right: '0%',
    bottom: '0%',
    background: 'transparent',
  },
};

const Menu = () => {
  const { isMobileWidth } = useWindowDimensions();
  const [menuStatus, setMenuStatus] = useState({ isOpen: false });

  const openModal = () => {
    setMenuStatus((prev) => ({
      ...prev,
      isOpen: true,
    }));
  };

  const closeModal = () => {
    setMenuStatus((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
  };

  return (
    <Layout>
      <Modal
        appElement={document.getElementById('root') as HTMLElement}
        isOpen={menuStatus.isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="menu"
      >
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              width: '100%',
              minHeight: 83,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <img src={LogoDark} alt="dark-logo" />
            <img style={{ cursor: 'pointer' }} src={Close} alt="close" onClick={closeModal} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
            <div style={{ display: 'inline-block' }}>
              {MobileMenuConfig.map((item, idx) => (
                <MenuItemMobile
                  key={`menu-item-${idx}`}
                  title={item.title}
                  img={item.img}
                  url={item.url}
                  isExternalLink={item.isExternalLink}
                />
              ))}
            </div>
          </div>
        </div>
      </Modal>
      {isMobileWidth ? (
        <img src={MenuIcon} alt="mobile-menu-icon" onClick={openModal} />
      ) : (
        MenuConfig.map((item, idx) => (
          <MenuItem
            key={`menu-item-${idx}`}
            title={item.title}
            img={item.img}
            url={item.url}
            isExternalLink={item.isExternalLink}
          />
        ))
      )}
    </Layout>
  );
};

export default Menu;

const Layout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
