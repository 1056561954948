import { GovernorContractsConfig } from '../components/DaoIntro/components/proposals/config';

export const zhidaoOnTallyURL = () => {
  GovernorContractsConfig.sort((a, b) => {
    return b.startBlock - a.startBlock;
  });
  return (
    process.env.REACT_APP_TALLY! + process.env.REACT_APP_CHAINID + `:${GovernorContractsConfig[0].address}`
  );
};
