export const daoIntro = {
  title: '知DAO',
  contentHtml1: `<p><span style="font-size:18px;color:#ffffff">知DAO，是去中心化自治知識中心。成員可以透過在提案中投票，決定議案是否執行。發起提案及參與投票皆至少需持有一枚知DAO NFT。</span></p>`,
  contentHtml2: ``,
};

export const tallyInterface = {
  title: 'Tally操作介面導覽',
  contentHtml1: `<p><span style="font-size:18px;color:#ffffff">知DAO進行鏈上投票的治理平台，選擇的是可支援Polygon鏈的Tally，提供透明公開的提案、投票、分析之治理工具。
  <br />於Tally鏈上投票前，需完成「Delegate Vote代表投票」設定，可以指定自己或是以虛擬錢包設定委託給其他「知DAO」NFT持有者。若在提案上鏈後才將代表投票設定完成，則無法擁有該提案之投票權限。</span>
  </p>`,
  contentHtml2: ``,
};

export const recentActivity = {
  title: 'DAO近期議案',
  contentHtml: ``,
};

export const instructionImages = [
  require('../../assets/images/dao-instructions/1.jpg'),
  require('../../assets/images/dao-instructions/2.jpg'),
  require('../../assets/images/dao-instructions/3.jpg'),
  require('../../assets/images/dao-instructions/4.jpg'),
  require('../../assets/images/dao-instructions/5.jpg'),
  require('../../assets/images/dao-instructions/6.jpg'),
  require('../../assets/images/dao-instructions/7.jpg'),
  require('../../assets/images/dao-instructions/8.jpg'),
  require('../../assets/images/dao-instructions/9.jpg'),
  require('../../assets/images/dao-instructions/10.jpg'),
  require('../../assets/images/dao-instructions/11.jpg'),
];
export const instructionMobileImages = [
  require('../../assets/images/dao-instructions/mobile/1.jpg'),
  require('../../assets/images/dao-instructions/mobile/2.jpg'),
  require('../../assets/images/dao-instructions/mobile/3.jpg'),
  require('../../assets/images/dao-instructions/mobile/4.jpg'),
  require('../../assets/images/dao-instructions/mobile/5.jpg'),
  require('../../assets/images/dao-instructions/mobile/6.jpg'),
  require('../../assets/images/dao-instructions/mobile/7.jpg'),
  require('../../assets/images/dao-instructions/mobile/8.jpg'),
  require('../../assets/images/dao-instructions/mobile/9.jpg'),
  require('../../assets/images/dao-instructions/mobile/10.jpg'),
  require('../../assets/images/dao-instructions/mobile/11.jpg'),
];
export const instructionDescription = ['', '', '', '', '', '', '', '', '', '', ''];
export const instructionMobileDescription = ['', '', '', '', '', '', '', '', '', '', ''];
