import styled from 'styled-components';

import Logo from '../../assets/images/logo.png';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { commonLayout } from '../../utils/constant';
import Menu from './components/Menu';

const Header = () => {
  const { isMobileWidth } = useWindowDimensions();

  return (
    <>
      <Layout1>
        <Content isMobileWidth={isMobileWidth}>
          <a href="/">
            <img src={Logo} alt="logo" />
          </a>
          <Menu />
        </Content>
      </Layout1>
    </>
  );
};

export default Header;

const Layout1 = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgb(97 165 255 / 50%);
`;

interface IMobile {
  isMobileWidth: boolean;
}
const Content = styled.div<IMobile>`
  width: ${commonLayout.viewWidth};
  max-width: 100vw;
  padding: 0px
    ${(props) =>
      props.isMobileWidth
        ? `${Number(commonLayout.viewPadding.replace('px', '')) + 16}px`
        : commonLayout.viewPadding};
  box-sizing: border-box;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
`;
