import { useState } from 'react';

import styled from 'styled-components';

import Arrow from '../../assets/images/arrow-down.png';
import { palette } from '../../utils/constant';

interface IFold {
  title: string;
  content: any;
}

const Fold: React.FC<IFold> = ({ title, content }) => {
  const [active, setActive] = useState(false);

  return (
    <Layout active={active}>
      <HeadLayout
        backgroundColor={palette.darkBlue}
        onClick={() => {
          setActive((prev) => !prev);
        }}
      >
        <div>{title}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transform: `${active ? 'rotate(180deg)' : 'none'}`,
          }}
        >
          <img style={{ maxWidth: 20 }} src={Arrow} alt="arrow" />
        </div>
      </HeadLayout>
      <div
        style={{
          height: active ? 'auto' : 0,
          boxSizing: 'border-box',
          overflow: 'hidden',
          background: '#d3f6f9',
          color: `${palette.darkBlue}`,
          width: '100%',
          border: 'solid 0px transparent',
          borderRadius: 10,
          padding: active ? '16px 20px' : 0,
          fontSize: '18px',
        }}
      >
        {content}
      </div>
    </Layout>
  );
};

export default Fold;

interface IQaLayout {
  active: boolean;
}

const Layout = styled.div<IQaLayout>`
  width: 100%;
  background: #d3f6f9;
  color: white;
  font-weight: 400;
  font-size: 18px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

interface IHeadLayout {
  backgroundColor: string;
}

const HeadLayout = styled.div<IHeadLayout>`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 20px 16px 20px;
  min-height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;
  :hover:not([disabled]) {
    background-color: ${palette.blue};
    color: white;
    -webkit-transition: background-color 300ms ease-out;
    -moz-transition: background-color 300ms ease-out;
    -o-transition: background-color 300ms ease-out;
    transition: background-color 300ms ease-out;
  }
`;
