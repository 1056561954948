export const carouselMedia = [
  {
    type: 'image',
    src: require('../../assets/images/dao-carousel/banner_web4.jpg'),
  },
  {
    type: 'image',
    src: require('../../assets/images/dao-carousel/banner_web3.jpg'),
  },
  {
    type: 'image',
    src: require('../../assets/images/dao-carousel/banner_web2.jpeg'),
  },
  {
    type: 'image',
    src: require('../../assets/images/landing-carousel/banner_web.jpeg'),
  },
  // {
  //   type: 'video',
  //   src: 'https://video.wixstatic.com/video/11062b_6de5c13fd6064d808a6f34e94c774dca/1080p/mp4/file.mp4',
  // },
  // {
  //   type: 'image',
  //   src: require('../../assets/images/landing-carousel/carousel-01.jpeg'),
  // },
  // {
  //   type: 'image',
  //   src: require('../../assets/images/landing-carousel/carousel-02.jpeg'),
  // },
  // {
  //   type: 'image',
  //   src: require('../../assets/images/landing-carousel/carousel-03.jpeg'),
  // },
  // {
  //   type: 'image',
  //   src: 'https://static.wixstatic.com/media/2e4f18_599fac2237994a9d8cb2d59d00d36323~mv2.jpg/v1/fill/w_1920,h_500,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2e4f18_599fac2237994a9d8cb2d59d00d36323~mv2.jpg',
  // },
  // {
  //   type: 'image',
  //   src: require('../../assets/images/landing-carousel/carousel-04.jpeg'),
  // },
];

export const carouselMediaMobile = [
  {
    type: 'image',
    src: require('../../assets/images/dao-carousel/mobile/banner_mobile4.jpg'),
  },
  {
    type: 'image',
    src: require('../../assets/images/dao-carousel/mobile/banner_mobile3.jpg'),
  },
  {
    type: 'image',
    src: require('../../assets/images/dao-carousel/mobile/banner_mobile2.jpg'),
  },
  {
    type: 'image',
    src: require('../../assets/images/landing-carousel/mobile/banner_mobile.jpeg'),
  },
  // {
  //   type: 'video',
  //   src: 'https://video.wixstatic.com/video/11062b_6de5c13fd6064d808a6f34e94c774dca/1080p/mp4/file.mp4',
  // },
  // {
  //   type: 'image',
  //   src: require('../../assets/images/landing-carousel/mobile/carousel-01.jpeg'),
  // },
  // {
  //   type: 'image',
  //   src: require('../../assets/images/landing-carousel/mobile/carousel-02.jpeg'),
  // },
  // {
  //   type: 'image',
  //   src: require('../../assets/images/landing-carousel/mobile/carousel-03.jpeg'),
  // },
  // {
  //   type: 'image',
  //   src: 'https://static.wixstatic.com/media/2e4f18_599fac2237994a9d8cb2d59d00d36323~mv2.jpg/v1/fill/w_1171,h_1501,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/2e4f18_599fac2237994a9d8cb2d59d00d36323~mv2.jpg',
  // },
  // {
  //   type: 'image',
  //   src: require('../../assets/images/landing-carousel/mobile/carousel-04.jpeg'),
  // },
];

export const carouselTitles = [
  {
    padding: '0px 0px 0px 32px',
    justify: 'flex-start',
    align: 'flex-start',
    top: '130px',
    bottom: 'auto',
    subtitle: '',
    title: '',
    id: 0,
  },
  {
    padding: '0px 0px 0px 32px',
    justify: 'flex-start',
    align: 'flex-start',
    top: '130px',
    bottom: 'auto',
    subtitle: '',
    title: '',
    id: 0,
  },
  {
    padding: '0px 0px 0px 32px',
    justify: 'flex-start',
    align: 'flex-start',
    top: '130px',
    bottom: 'auto',
    subtitle: '',
    title: '',
    id: 0,
  },
  {
    padding: '0px 0px 0px 32px',
    justify: 'flex-start',
    align: 'flex-start',
    top: '130px',
    bottom: 'auto',
    subtitle: '',
    title: '',
    id: 0,
  },
  // {
  //   padding: '0px 0px 0px 32px',
  //   justify: 'flex-start',
  //   align: 'flex-start',
  //   top: '130px',
  //   bottom: 'auto',
  //   subtitle: 'subtitle',
  //   title: '智慧果實左上',
  //   id: 0,
  // },
  // {
  //   padding: '0px 32px 0px 0px',
  //   justify: 'flex-start',
  //   align: 'flex-end',
  //   top: '130px',
  //   bottom: 'auto',
  //   subtitle: 'subtitle',
  //   title: '智慧果實右上',
  //   id: 1,
  // },
  // {
  //   padding: '0px 32px 0px 0px',
  //   justify: 'flex-end',
  //   align: 'flex-end',
  //   top: 'auto',
  //   bottom: '50px',
  //   subtitle: 'subtitle',
  //   title: '智慧果實右下',
  //   id: 2,
  // },
  // {
  //   padding: '0px 32px 0px 0px',
  //   justify: 'flex-end',
  //   align: 'flex-end',
  //   top: 'auto',
  //   bottom: '50px',
  //   subtitle: 'subtitle',
  //   title: '智慧果實右下',
  //   id: 2,
  // },
  // {
  //   padding: '0px 0px 0px 32px',
  //   justify: 'flex-end',
  //   align: 'flex-start',
  //   top: 'auto',
  //   bottom: '50px',
  //   subtitle: 'subtitle',
  //   title: '智慧果實左下',
  //   id: 3,
  // },
  // {
  //   padding: '0px 0px 0px 32px',
  //   justify: 'flex-end',
  //   align: 'flex-start',
  //   top: 'auto',
  //   bottom: '50px',
  //   subtitle: 'subtitle',
  //   title: '智慧果實左下',
  //   id: 3,
  // },
];
