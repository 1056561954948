import { Contract, ethers } from 'ethers';
import ZhidaoGovernorJson from '../abis/ZHIDAO_Governor_Bravo_ABI.json';
import ERC20Json from '../abis/ERC20.json';
import { useMemo } from 'react';
import { getContract } from '../utils/getContract';
import { ZHIDAO_Governor_Bravo_ABI, ERC20 } from '../abis/types';
import useProvider from './useProvider';

export type IAddressOrAddressMap = string | { [chainId: number]: string };
interface IGovernorContracts {
  contract: ZHIDAO_Governor_Bravo_ABI;
  startBlock: number;
}

export interface IGovernorContractsConfig {
  address: string;
  startBlock: number;
}

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: IAddressOrAddressMap | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const chainId = Number(process.env.REACT_APP_CHAINID);
  const provider = useProvider();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null;

    let address: string | undefined;
    if (typeof addressOrAddressMap === 'string') {
      address = addressOrAddressMap;
    } else {
      address = addressOrAddressMap[chainId];
    }

    if (!address) return null;
    try {
      return getContract(address, ABI, provider, undefined);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [addressOrAddressMap, ABI, provider, chainId]) as T;
}

export function useZhidaoGovernorContract(
  governorContractConfig: IGovernorContractsConfig[]
): IGovernorContracts[] {
  const provider = useProvider();
  governorContractConfig.sort((a, b) => {
    return a.startBlock - b.startBlock;
  });
  return useMemo(() => {
    let tmpContractList: IGovernorContracts[] = [];
    for (let idx = 0; idx < governorContractConfig.length; idx++) {
      tmpContractList.push({
        contract: getContract(
          governorContractConfig[idx].address,
          ZhidaoGovernorJson,
          provider,
          undefined
        ) as ZHIDAO_Governor_Bravo_ABI,
        startBlock: governorContractConfig[idx].startBlock,
      });
    }
    return tmpContractList;
  }, [governorContractConfig, provider]);
}

export const zhidaoGovernorInterface = new ethers.utils.Interface(ZhidaoGovernorJson);

export function useERC20Contract(tokenAddress?: IAddressOrAddressMap, withSignerIfPossible?: boolean) {
  return useContract<ERC20>(tokenAddress, ERC20Json, withSignerIfPossible);
}

export const erc20Interface = new ethers.utils.Interface(ERC20Json);
