const POLYGON_MAINNET_ID = '137';

// Polygon Mainnet contract
export const GovernorContractsConfig =
  process.env.REACT_APP_CHAINID === POLYGON_MAINNET_ID
    ? [
        {
          address: '0x5835F8C675BE78F991fE8c3004c649428F768442',
          startBlock: 31510018,
        },
      ]
    : [
        {
          address: '0xeaeDD00Bbb1B5A82ce452c480dD864d01c300E4b',
          startBlock: 11037673,
        },
        {
          address: '0x8804F70EE793361FAf05981add22bcD0D560caf1',
          startBlock: 11037943,
        },
      ];

// Rinkeby test contract ^^^^^
