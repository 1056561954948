import './landingDao.css';

import {
  useEffect,
  useRef,
  useState,
} from 'react';

import styled from 'styled-components';

import DaoBackground from '../../assets/images/landing-dao-background.jpg';
import DaoBackgroundMobile
  from '../../assets/images/landing-dao-mobile-background.jpeg';
import VaultBalance from '../../components/VaultBalance';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { commonLayout } from '../../utils/constant';
import { word } from './config';

const LandingDao = () => {
  const { isMobileWidth } = useWindowDimensions();
  const [childWidth, setChildWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setChildWidth(ref.current?.offsetWidth ?? 0);
  }, [ref]);

  return (
    <Layout isMobileWidth={isMobileWidth}>
      {!isMobileWidth ? (
        <Content>
          <TitleLayout>
            <span id="landing-welcome-subtite">{word.subtitle}</span>
            <span id="landing-welcome-title">{word.title}</span>
            <p
              style={{
                width: childWidth,
                marginTop: 16,
                color: 'white',
                lineHeight: 1.5,
                textAlign: 'left',
                fontSize: 18,
              }}
            >
              「知DAO」是由 nspace 發起的社群自治元宇宙知識中心，透過發行NFT賦予持有者共同治理
              DAO的權力，持有者可以在「知DAO」進行提案，找尋你實現理想所需要資金、團隊、推廣夥伴。
            </p>
          </TitleLayout>
          <VaultBalance isMobileWidth={isMobileWidth} ref={ref} />
        </Content>
      ) : (
        <MobileContent>
          <MobileTitleLayout>
            <span id="landing-welcome-subtite">{word.subtitle}</span>
            <span id="landing-welcome-title">{word.title}</span>
            <p
              style={{
                width: '100%',
                marginTop: 16,
                color: 'white',
                lineHeight: 1.5,
                textAlign: 'left',
                fontSize: 18,
              }}
            >
              「知DAO」是由 nspace 發起的社群自治元宇宙知識中心，透過發行NFT賦予持有者共同治理
              DAO的權力，持有者可以在「知DAO」進行提案，找尋你實現理想所需要資金、團隊、推廣夥伴。
            </p>
          </MobileTitleLayout>
          <VaultBalance isMobileWidth={isMobileWidth} />
        </MobileContent>
      )}
    </Layout>
  );
};

export default LandingDao;

interface iMobile {
  isMobileWidth: boolean;
}
const Layout = styled.div<iMobile>`
  width: 100%;
  min-height: 584px;
  background: url(${(props) => (props.isMobileWidth ? DaoBackgroundMobile : DaoBackground)}) no-repeat
    ${(props) => (props.isMobileWidth ? 'top / cover' : 'center')};
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: ${commonLayout.viewWidth};
  min-height: 584px;
  max-width: 100vw;
  padding: 0px ${commonLayout.viewPadding};
  box-sizing: border-box;
`;

const TitleLayout = styled.div`
  width: 100%;
  display: inline-block;
  line-height: 1;
  vertical-align: bottom;
  margin-top: 92px;
  margin-bottom: 110px;
`;

const MobileContent = styled.div`
  width: ${commonLayout.viewWidth};
  min-height: 100vh;
  max-width: 100vw;
  padding: 96px ${commonLayout.viewPadding};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const MobileTitleLayout = styled.div`
  width: 100%;
  display: inline-block;
  line-height: 1;
  vertical-align: bottom;
  text-align: center;
  margin-top: 92px;
  margin-bottom: 32px;
`;
