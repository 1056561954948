import { useMemo } from 'react';

import { ethers } from 'ethers';

export enum Network {
  POLYGON = 'polygon',
  ETHEREUM = 'ethereum',
}

const useProvider = (networkName = Network.POLYGON) => {
  return useMemo(() => {
    if (networkName === Network.POLYGON) {
      return new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_JSON_RPC_PROVIDER); // polygon
    } else {
      return new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ETH_ALCHEMY_JSON_RPC_PROVIDER); // eth
    }
  }, [networkName]);
};

export default useProvider;
