import './index.css';

import styled from 'styled-components';

interface IStatusMapping {
  [key: number]: string;
}
const StatusMapping: IStatusMapping = {
  0: '待辦中', // Pending
  1: '進行中', // Active
  2: '已取消', // Canceled
  3: '未通過', // Defeated
  4: '已通過', // Succeeded
  5: '列隊中', // Queued
  6: '已過期', // Expired
  7: '已執行', //Executed
};

const Proposal = ({ title = '', status = 0, pId = '', governorContract = '' }) => {
  return (
    <a
      style={{ textDecoration: 'none' }}
      href={
        process.env.REACT_APP_TALLY! + process.env.REACT_APP_CHAINID! + `:${governorContract}/proposal/${pId}`
      }
      target="_blank"
      rel="noreferrer"
    >
      <Layout>
        <div className={`status status-${status}`}>
          <div>{title}</div>
          <div>{StatusMapping[status]}</div>
        </div>
      </Layout>
    </a>
  );
};

export default Proposal;

const Layout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`;
