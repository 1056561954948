import DiscordLogo from '../../assets/images/header/discord.svg';
import DocLogo from '../../assets/images/header/doc.svg';
import DaoLogo from '../../assets/images/header/friends.svg';
import DiscordLogoMobile from '../../assets/images/header/mobile/discord.svg';
import DocLogoMobile from '../../assets/images/header/mobile/doc.svg';
import DaoLogoMobile from '../../assets/images/header/mobile/friends.svg';
import CoinMobile from '../../assets/images/header/mobile/opensea.svg';
import Coin from '../../assets/images/header/opensea.svg';
import { externalUrl } from '../../config';

export const MenuConfig = [
  {
    title: 'DAO',
    url: '/dao',
    img: DaoLogo,
    isExternalLink: false,
  },
  {
    title: 'Docs',
    url: externalUrl.docs,
    img: DocLogo,
    isExternalLink: true,
  },
  {
    title: 'Discord',
    url: externalUrl.discord,
    img: DiscordLogo,
    isExternalLink: true,
  },
  {
    title: '二級市場',
    url: process.env.REACT_APP_OPENSEA!,
    img: Coin,
    isExternalLink: true,
  },
];

export const MobileMenuConfig = [
  {
    title: 'DAO',
    url: '/dao',
    img: DaoLogoMobile,
    isExternalLink: false,
  },
  {
    title: 'Docs',
    url: externalUrl.docs,
    img: DocLogoMobile,
    isExternalLink: true,
  },
  {
    title: 'Discord',
    url: externalUrl.discord,
    img: DiscordLogoMobile,
    isExternalLink: true,
  },
  {
    title: '二級市場',
    url: process.env.REACT_APP_OPENSEA!,
    img: CoinMobile,
    isExternalLink: true,
  },
];
