import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { palette } from '../../../../utils/constant';

interface IMenuItem {
  img: string;
  url: string;
  title: string;
  isExternalLink: boolean;
}

const MenuItemMobile: React.FC<IMenuItem> = ({ img, url, title, isExternalLink }) => {
  if (isExternalLink) {
    return (
      <a style={{ textDecoration: 'none' }} href={url} target="_blank" rel="noreferrer">
        <Layout>
          <div style={{ width: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img style={{ marginRight: 6, height: 36, width: 36, fill: '#5c7b79' }} src={img} alt="" />
          </div>
          <Title>{title}</Title>
        </Layout>
      </a>
    );
  }

  return (
    <Link style={{ textDecoration: 'none' }} to={url}>
      <Layout>
        <div style={{ width: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img style={{ marginRight: 6, height: 36, width: 36, fill: '#5c7b79' }} src={img} alt="" />
        </div>

        <Title>{title}</Title>
      </Layout>
    </Link>
  );
};

export default MenuItemMobile;

const Layout = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 9px;
  margin-left: 8px;
  border-radius: 8px;
`;

const Title = styled.div`
  color: ${palette.darkBlue};
  font-size: 32px;
  margin-left: 16px;
  font-family: sans-serif;
`;
