import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import usePageTracking from './components/Ga';
import Dao from './pages/Dao';
import Landing from './pages/Landing';

function EbeddedGa() {
  usePageTracking();

  return <></>;
}

function App() {
  return (
    <BrowserRouter>
      <EbeddedGa />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/dao" element={<Dao />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
