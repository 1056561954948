import { useMemo, useState } from 'react';
import TallyInstruction from '../Instruction';
import styled from 'styled-components';
import {
  instructionImages,
  instructionMobileImages,
  instructionDescription,
  instructionMobileDescription,
} from '../../config';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import useWindowDimensions from '../../../../hooks/useWindowDimension';

const TallyInstructions = () => {
  const { isMobileWidth } = useWindowDimensions();
  const [lightBoxStatus, setLightBoxStatus] = useState({ isOpen: false, imgIdx: 0 });

  const instructionSetting = useMemo(() => {
    return isMobileWidth
      ? { imgs: instructionMobileImages, desc: instructionMobileDescription }
      : { imgs: instructionImages, desc: instructionDescription };
  }, [isMobileWidth]);

  const openInstruction = (imgIdx: number) => {
    setLightBoxStatus({ isOpen: true, imgIdx: imgIdx });
  };

  const closeInstruction = () => {
    setLightBoxStatus((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const prevReq = () => {
    setLightBoxStatus((prev) => ({
      ...prev,
      imgIdx: (prev.imgIdx + instructionSetting.imgs.length - 1) % instructionSetting.imgs.length,
    }));
  };

  const nextReq = () => {
    setLightBoxStatus((prev) => ({
      ...prev,
      imgIdx: (prev.imgIdx + 1) % instructionSetting.imgs.length,
    }));
  };

  const hackLightBoxFix = () => {
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <Layout>
      {lightBoxStatus.isOpen && (
        <Lightbox
          mainSrc={instructionSetting.imgs[lightBoxStatus.imgIdx]}
          nextSrc={instructionSetting.imgs[(lightBoxStatus.imgIdx + 1) % instructionSetting.imgs.length]}
          prevSrc={
            instructionSetting.imgs[
              (lightBoxStatus.imgIdx + instructionSetting.imgs.length - 1) % instructionSetting.imgs.length
            ]
          }
          onCloseRequest={closeInstruction}
          onMovePrevRequest={prevReq}
          onMoveNextRequest={nextReq}
          onImageLoad={hackLightBoxFix}
        />
      )}
      {instructionSetting.imgs.map((item, idx) => (
        <TallyInstruction
          key={`instru-${idx}`}
          img={item}
          description={instructionSetting.desc[idx]}
          idx={idx}
          onClick={openInstruction}
        />
      ))}
    </Layout>
  );
};

export default TallyInstructions;

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 16px 0px;
`;
