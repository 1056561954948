import './index.css';

import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import SyncLoader from 'react-spinners/SyncLoader';
import styled from 'styled-components';

import { ZHIDAO_Governor_Bravo_ABI } from '../../../../abis/types';
import { externalUrl } from '../../../../config/index';
import {
  useZhidaoGovernorContract,
  zhidaoGovernorInterface,
} from '../../../../hooks/useContract';
import { zhidaoOnTallyURL } from '../../../../utils/tallyHelper';
import Button from '../../../Button';
import NotifyButton from '../../../NotifyButton';
import Proposal from '../proposal';
import { GovernorContractsConfig } from './config';

interface IProposal {
  title: string;
  id: string;
  status: number;
  contract: ZHIDAO_Governor_Bravo_ABI; // the governor contract that this proposal belongs
  startBlock: number; // the governor contract startblock that this proposal belongs
}

const Proposals = () => {
  const showDataIndex = useRef(0);
  const [proposalRawList, setProposalRawList] = useState<IProposal[]>([]);
  const [totalShowNumber, setTotalShowNumber] = useState(10);
  const [showData, setShowData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const zhidaoGovernorContracts = useZhidaoGovernorContract(GovernorContractsConfig);

  const loadMore = useCallback(() => {
    setIsLoading(true);
    setTotalShowNumber((prev) => prev + 5);
  }, []);

  const prepareShowData = useCallback(async () => {
    // if proposalRawList is empty, fetch logs from blockchain
    if (zhidaoGovernorContracts.length > 0 && zhidaoGovernorInterface) {
      setIsLoading(true);
      let tmpRawList: IProposal[] = [];
      for (let idx = 0; idx < zhidaoGovernorContracts.length; idx++) {
        let createdEventFilter = zhidaoGovernorContracts[idx].contract.filters.ProposalCreated();
        const createdEvents = await zhidaoGovernorContracts[idx].contract.queryFilter(
          createdEventFilter!,
          Number(zhidaoGovernorContracts[idx].startBlock)
        );
        createdEvents.sort((a, b) => {
          return b.blockNumber - a.blockNumber;
        });
        const newProposalList = createdEvents.map((event) => {
          return {
            title: zhidaoGovernorInterface.parseLog(event).args[8].split('\n')[0],
            id: zhidaoGovernorInterface.parseLog(event).args[0].toString(),
            status: -1,
            contract: zhidaoGovernorContracts[idx].contract,
            startBlock: zhidaoGovernorContracts[idx].startBlock,
          };
        });
        newProposalList.forEach((proposal, idx) => {
          if (!tmpRawList.includes(proposal)) {
            tmpRawList.push(proposal);
          }
        });
      }
      setProposalRawList(tmpRawList);
      showDataIndex.current = 0;
    }
  }, [zhidaoGovernorContracts]);

  useEffect(() => {
    (async () => {
      let idx = showDataIndex.current;
      // if proposalRawList is not empty, get its status through contract function call, start from showDataIndex to min(totalShowNumber,proposalRawList.length)
      if (proposalRawList.length > 0) {
        for (; idx < totalShowNumber && idx < proposalRawList.length; idx++) {
          try {
            proposalRawList[idx].status =
              (await proposalRawList[idx].contract?.state(proposalRawList[idx].id)) || -1;

            // status 3 and 4 need some calculation to determined the correct status
            if (proposalRawList[idx].status === 3 || proposalRawList[idx].status === 4) {
              const tmpProposalDetail =
                (await proposalRawList[idx].contract?.proposals(proposalRawList[idx].id)) || undefined;

              const quorum = (
                await proposalRawList[idx].contract?.quorum(proposalRawList[idx].startBlock)
              ).toNumber();
              // const quorumNum = (
              //   (await proposalRawList.current[idx].contract?.quorumNumerator()) || ethers.BigNumber.from(100)
              // ).toNumber();
              const forVotes = tmpProposalDetail?.forVotes.toNumber() || 0;
              // const against = tmpProposalDetail?.againstVotes.toNumber() || 0;
              // const abstain = tmpProposalDetail?.abstainVotes.toNumber() || 0;

              // legacy quorum method
              // for / (for + against + abstain) >= quorumNumerator
              // if (forVotes / (forVotes + against + abstain) >= quorumNum / 100) {
              //   proposalRawList.current[idx].status = 4;
              // } else {
              //   proposalRawList.current[idx].status = 3;
              // }

              // match tally quorum method
              if (forVotes >= quorum) {
                proposalRawList[idx].status = 4;
              } else {
                proposalRawList[idx].status = 3;
              }
            }
          } catch (error) {
            console.log('error: ', error);
          }
        }
        showDataIndex.current = idx;
      }
      const tmpDom =
        proposalRawList.length > 0
          ? proposalRawList
              .filter((item) => item.status !== -1)
              .map((item, domIdx) => {
                return (
                  <Proposal
                    key={`proposal-${domIdx}`}
                    title={item.title}
                    status={item.status}
                    pId={item.id}
                    governorContract={item.contract.address}
                  />
                );
              })
          : [
              <div key="no-proposal" style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>
                (暫無議案)
              </div>,
            ];
      setShowData(tmpDom);
      setIsLoading(false);
    })();
  }, [proposalRawList, totalShowNumber]);

  useEffect(() => {
    prepareShowData();
  }, [prepareShowData]);

  return (
    <Layout>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '16px 0px 16px 0px',
          }}
        >
          <SyncLoader size={8} color="white" />
        </div>
      ) : (
        <>{showData}</>
      )}
      <div style={{ marginTop: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <a
          style={{ textDecoration: 'none' }}
          href={externalUrl.announcement}
          target="_blank"
          rel="noreferrer"
        >
          <NotifyButton title="知DAO 近期公告" />
        </a>
        <div style={{ width: 16 }} />
        <a style={{ textDecoration: 'none' }} href={zhidaoOnTallyURL()} target="_blank" rel="noreferrer">
          <Button title="前往 知DAO on Tally" />
        </a>
        <div style={{ width: 16 }} />
        <Button
          id="load-more-btn"
          title="載入更多"
          onClick={loadMore}
          disabled={isLoading || totalShowNumber >= proposalRawList.length}
        />
      </div>
    </Layout>
  );
};

export default Proposals;

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
