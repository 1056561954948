import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { ethers } from 'ethers';
import SyncLoader from 'react-spinners/SyncLoader';
import styled from 'styled-components';

import useProvider, { Network } from '../../hooks/useProvider';

interface IVaultBalance {
  isMobileWidth?: boolean;
  // ref?: React.Ref<HTMLDivElement>;
}
const VaultBalance = forwardRef<HTMLDivElement, IVaultBalance>(({ isMobileWidth }, ref) => {
  const ethProvider = useProvider(Network.ETHEREUM);

  const [vaultValue, setVaultValue] = useState({
    remain: 0,
    total: 0,
    isLoading: true,
  });

  const getVaultBalance = useCallback(async () => {
    try {
      const remain = Math.round(
        Number(
          ethers.utils.formatEther(await ethProvider.getBalance(process.env.REACT_APP_VAULT_ADDRESS || ''))
        )
      );

      setVaultValue({
        remain: remain,
        total: 0,
        isLoading: false,
      });
    } catch (error) {
      console.log('error in getVaultBalance: ', error);
    }
  }, [ethProvider]);

  useEffect(() => {
    getVaultBalance();
  }, [getVaultBalance]);

  return (
    <Layout
      isMobileWidth={isMobileWidth}
      ref={ref}
      onClick={() =>
        window.open(`https://etherscan.io/address/${process.env.REACT_APP_VAULT_ADDRESS}`, '_blank')
      }
    >
      <Title>國庫餘額</Title>
      {vaultValue.isLoading ? (
        <Value style={{ marginLeft: 20, marginRight: 20 }}>
          <SyncLoader size={8} color="#282828" />
        </Value>
      ) : (
        <div
          style={{
            display: 'inline',
            minWidth: `${isMobileWidth ? '0px' : '440px'}`,
            textAlign: 'right',
            flexGrow: 1,
          }}
        >
          <Value style={{ marginLeft: 20, marginRight: 20 }}>{vaultValue.remain}</Value>
          <span>ETH</span>
        </div>
      )}
    </Layout>
  );
});

export default VaultBalance;

const Layout = styled.div<IVaultBalance>`
  width: ${(props) => (props.isMobileWidth ? '100%' : 'auto')};
  max-width: ${(props) => (props.isMobileWidth ? '100%' : 'none')};
  min-width: ${(props) => (props.isMobileWidth ? '0px' : '520px')};
  height: 61px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding-left: 20px;
  padding-right: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #282828;
  line-height: 61px;
  cursor: pointer;
`;

const Title = styled.div``;

const Value = styled.span``;
