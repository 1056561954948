import PropTypes from 'prop-types';
import styled from 'styled-components';

import { palette } from '../../utils/constant';

const Button = ({ title = '', onClick = () => {}, children = {}, primary = false, ...props }) => {
  return (
    <ButtonStyle primary onClick={onClick} {...props}>
      {title}
    </ButtonStyle>
  );
};

export default Button;

Button.prototype = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  primary: PropTypes.bool,
  width: PropTypes.string,
  isMobileWidth: PropTypes.bool,
  height: PropTypes.number,
};

type ButtonProps = {
  primary: boolean;
  disabled?: boolean;
  width?: string;
  maxWidth?: string;
  isMobileWidth?: boolean;
  height?: number;
};
const ButtonStyle = styled.button<ButtonProps>`
  font-family: sans-serif;
  width: ${(props) => (props.isMobileWidth ? '100%' : 'auto')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
  height: ${(props) => (props.height ? props.height : 'auto')}px;
  min-height: 42px;
  background-color: ${palette.darkBlue};
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding: 6px 20px;
  border: solid 0px transparent;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  :hover:not([disabled]) {
    background-color: ${palette.blue};
    color: white;
    -webkit-transition: background-color 300ms ease-out;
    -moz-transition: background-color 300ms ease-out;
    -o-transition: background-color 300ms ease-out;
    transition: background-color 300ms ease-out;
  }
  :disabled {
    background: #fff;
    color: #ccc;
    cursor: no-drop;
  }
`;
