import { providers, Contract } from 'ethers';

export const AddressZero = '0x0000000000000000000000000000000000000000';

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: providers.JsonRpcProvider,
  account?: string
): Contract {
  if (address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, library);
}
