import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimension';
import Button from '../Button';

const NotifyButton = ({ title = '', onClick = () => {} }) => {
  const { isMobileWidth } = useWindowDimensions();

  return (
    <Layout isMobileWidth={isMobileWidth}>
      <NofityPoint />
      <Button title={title} onClick={onClick} isMobileWidth={isMobileWidth} />
    </Layout>
  );
};

export default NotifyButton;

interface IMobile {
  isMobileWidth: boolean;
}
const Layout = styled.div<IMobile>`
  width: ${(props) => (props.isMobileWidth ? '100%' : 'auto')};
  display: flex;
  position: relative;
`;

const NofityPoint = styled.div`
  background-color: red;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 25px;
`;
