import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { palette } from '../../../../utils/constant';

interface IMenuItem {
  img: string;
  url: string;
  title: string;
  isExternalLink: boolean;
}

const MenuItem: React.FC<IMenuItem> = ({ img, url, title, isExternalLink }) => {
  if (isExternalLink) {
    return (
      <a style={{ textDecoration: 'none' }} href={url} target="_blank" rel="noreferrer">
        <Layout>
          <img style={{ marginRight: 6, maxHeight: 16, maxWidth: 16 }} src={img} alt="" />
          <Title>{title}</Title>
        </Layout>
      </a>
    );
  }

  return (
    <Link style={{ textDecoration: 'none' }} to={url}>
      <Layout>
        <img style={{ marginRight: 6, maxHeight: 16, maxWidth: 16 }} src={img} alt="" />

        <Title>{title}</Title>
      </Layout>
    </Link>
  );
};

export default MenuItem;

const Layout = styled.div`
  display: flex;
  justify-content: center;
  width: 120px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 9px;
  margin-left: 8px;
  background-color: ${palette.darkBlue};
  border-radius: 8px;
  :hover:not([disabled]) {
    background-color: ${palette.blue};
    color: white;
    -webkit-transition: background-color 300ms ease-out;
    -moz-transition: background-color 300ms ease-out;
    -o-transition: background-color 300ms ease-out;
    transition: background-color 300ms ease-out;
  }
`;

const Title = styled.div`
  color: white;
  font-style: normal;
  font-weight: 700;
  font-family: sans-serif;
`;
