import styled from 'styled-components';

import useWindowDimensions from '../../hooks/useWindowDimension';
import { palette } from '../../utils/constant';
import { footerImages } from './config';

const Footer = () => {
  const { isMobileWidth } = useWindowDimensions();

  return (
    <Layout isMobileWidth={isMobileWidth}>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        {footerImages.map((item, idx) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 8,
              marginRight: 8,
            }}
            key={`footer-${idx}`}
          >
            <a href={item.url} target="_blank" rel="noreferrer">
              <img
                style={{ display: 'block', margin: 'auto', maxWidth: 25, maxHeight: 25 }}
                src={item.img}
                alt="dc-icon"
              ></img>
            </a>
          </div>
        ))}
      </div>
      <div>
        © {new Date().getFullYear()} by{' '}
        <Nspace href="https://www.nspace.media/" target="_blank" rel="noreferrer">
          nspace
        </Nspace>
      </div>
    </Layout>
  );
};

export default Footer;

interface ILayout {
  isMobileWidth: boolean;
}

const Layout = styled.div<ILayout>`
  width: 100%;
  height: ${(props) => (!props.isMobileWidth ? '150px' : '150px')};
  background-color: ${palette.darkBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
`;

const Nspace = styled.a`
  color: white;
  text-decoration: none;
  :hover {
    color: #cd77de;
    border-bottom: 1px solid #cd77de;
    cursor: pointer;
  }
`;
