export const commonLayout = {
  viewWidth: '1029px',
  viewPadding: '15px',
  minDesktopWidth: '1024px',
};

export const palette = {
  yellow: '#CCFF66',
  green: '#66FFCC',
  blue: '#6699FF',
  middleGreen: '#99C750',
  darkGreen: '#56D1AD',
  darkBlue: '#537CCF',
};
