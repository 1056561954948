import Header from '../../components/Header';
import styled from 'styled-components';
import DaoCarousel from '../../components/DaoCarousel';
import DaoIntro from '../../components/DaoIntro';
import Footer from '../../components/Footer';

const Dao = () => {
  return (
    <Layout>
      <Header />
      <DaoCarousel />
      <DaoIntro />
      <Footer />
    </Layout>
  );
};

export default Dao;

const Layout = styled.div`
  width: 100%;
`;
