import {
  useEffect,
  useState,
} from 'react';

import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA ?? 'foo', {
      testMode: process.env.REACT_APP_GA ? false : true,
    });
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default usePageTracking;
