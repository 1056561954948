export const word = {
  title: '知DAO Q&A',
  contentHtml: `<div style="color:white;font-size:18px;">
  <p>「知DAO」是由 nspace 發起的社群自治元宇宙知識中心，透過發行NFT賦予持有者共同治理 DAO的權力，持有者可以在「知DAO」進行提案，找尋你實現理想所需要資金、團隊、推廣夥伴。</p>
  <p>參與「知DAO」就像成為創投加速器的一份子，可以在這裡找到夥伴、資金或用行動支持彼此的構想，透過更多的成功提案，讓你的 NFT 愈來愈有價值。</p>
  <p>出版業、講師或是線上課程平台，也可以從知DAO挖掘具有潛力的提案項目，共同投資經營或者將既有的IP授權與知DAO聯名發行，成為共同投資人。</p>
  </div>`,
};

export const introList = [
  {
    title: '什麼是 DAO',
    content: `是以區塊鏈技術為基礎，由下而上的去中心化自治組織。 透過電腦與電腦之間的共識來建立協定，然後透過程式把營運性的事寫成智能合約，這些智能合約會自動執行，以最小化人為的介入和干擾，降低交易成本。`,
  },
  {
    title: '什麼是知DAO NFT',
    content: `
      <ol>
        <li>知DAO 所發行的 NFT 共有兩種：有治理權限、無治理權限。</li>
        <li>有治理權限的NFT 為治理者憑證，持有者可以在DAO中參與提案和投票來參與知DAO治理。</li>
        <li>未來發行方式將由DAO決議。</li>
      </ol>
    `,
  },
  {
    title: 'NFT 如何獲取？',
    content: `
      <ol>
        <li>於2022/5/12~2022/5/26，參與NFT實戰勝經活動</li>
        <li><a id="opensea-url">二級市場</a>與持有者購買</li>
      </ol>
    `,
  },
  {
    title: '我可以在哪裡獲得關於知DAO的更多訊息？',
    content: `
    <p>
    首先，閱讀我們的
    <a href="https://www.notion.so/Zih-Dao-7ba09d3c5473457cadb10c5300208dc5" target="_blank" rel="noreferrer">白皮書</a>
    ，能夠更了解知DAO的內容以及願景。如需要更多的訊息請關注
    <a href="https://zhidao.nspace.media/" target="_blank" rel="noreferrer">nspace官網</a>
    的相關貼文，並加入
    <a href="https://zhidao.nspace.media/discord" target="_blank" rel="noreferrer">nspace Discord</a>
    以取得知DAO社群的最新動態。
    </p>

    `,
  },
  {
    title: '我是創作者，想要在知DAO提案，但我沒有知DAO NFT，我該與誰聯繫？',
    content: `nspace MOD | Jonathan#0863 是知DAO Discord的社群管理員，可以聯繫洽談提案細節。`,
  },
];
