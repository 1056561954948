import Header from '../../components/Header';
import styled from 'styled-components';
import LandingCarousel from '../../components/LandingCarousel';
import LandingDao from '../../components/LandingDao';
import LandingDaoIntro from '../../components/LandingDaoIntro';
import Footer from '../../components/Footer';

const Landing = () => {
  return (
    <Layout>
      <Header />
      <LandingCarousel />
      <LandingDao />
      <LandingDaoIntro />
      <Footer />
    </Layout>
  );
};

export default Landing;

const Layout = styled.div`
  width: 100%;
`;
