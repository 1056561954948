import styled from 'styled-components';
import useWindowDimensions from '../../../../hooks/useWindowDimension';

interface ITallyInstruction {
  img: string;
  description: string;
  idx: number;
  onClick: Function;
}
const TallyInstruction: React.FC<ITallyInstruction> = ({ img, description, idx, onClick }) => {
  const { isMobileWidth } = useWindowDimensions();

  return (
    <Layout
      isMobileWidth={isMobileWidth}
      onClick={() => {
        onClick(idx);
      }}
    >
      <img style={{ maxWidth: '100%' }} src={img} alt={`instruction-${idx}`} />
      <Desc>{description}</Desc>
    </Layout>
  );
};

export default TallyInstruction;

interface IMobile {
  isMobileWidth: boolean;
}
const Layout = styled.div<IMobile>`
  width: ${(props) => (props.isMobileWidth ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 8px;
  cursor: pointer;
`;

const Desc = styled.div`
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 16px 0px;
`;
